/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import Chart from "react-apexcharts";

import { toast, ToastContainer } from "react-toastify";

import formatNumber from "format-number";

import { format } from "date-fns";

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from "react-router-dom";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import Swal from "sweetalert2";

import PropTypes from "prop-types";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

const CampanhaEdit = ({ permissaoAtualizar }) => {
  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, logoTipo, user } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [turmas, setturma] = useState([]);
  const [turmas_curso, setTurmaCurso] = useState([]);
  const [curso_turmas, setCursoTurmas] = useState([]);
  const [cursos, setcurso] = useState([]);
  const [cursosInscricoes, setCursoInscricao] = useState([]);
  const [estatistica, setEstatistica] = useState([]);
  const [periodos, setPeriodos] = useState([]);

  const [inputs, setInputs] = useState({});
  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState("");

  const [selectedturmaIds, setSelectedturmaIds] = useState([]);

  const [nomeFilter, setNomeFilter] = useState("");
  const [curso_selecionado, setCursoSelecionado] = useState([]);
  const [data1Filter, setData1Filter] = useState("");
  const [data2Filter, setData2Filter] = useState("");
  const [modoFilter, setModoFilter] = useState("");
  const [precoFilter, setPrecoFilter] = useState("");
  const [frequenciaFilter, setFrequenciaFilter] = useState("");
  const [periodoFilter, setPeriodoFilter] = useState("");
  const [coletivoFilter, setColetivoFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {
      setData1Filter(null);
      setData2Filter(null);
      setModoFilter("");
      setPrecoFilter("");
      setFrequenciaFilter("");
      setPeriodoFilter("");
      setColetivoFilter("");
      setEstadoFilter();
    }
  };

  /* Turmas inicio */

  const confirmEliminar = (id) => {
    Swal.fire({
      title: "Tem a certeza que quer remover?",
      text: "Não sera possivel reverter o processo!",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(function (t) {
      if (t.isConfirmed) {
        // Se o usuário confirmar, envie a solicitação
        handleEliminar(id);
      }
    });
  };

  const handleEliminar = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);

      const response = await http.post("/turma/eliminar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      getturma();

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Atualize a lista de turmas após ativar/desativar
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const irParaTurma = (id) => {
    const element = document.querySelector(".btn-close");

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push("/dream-pos/turma/turmaedit/" + id);
  };

  function calcularTotalPagamentosFormandos(pagamentos) {
    if (!pagamentos || pagamentos.length === 0) {
      return 0;
    }

    const somaTotal = pagamentos.reduce(
      (total, pagamento) => total + Number(pagamento.valor),
      0
    );
    return somaTotal;
  }

  const formatarNumero = (
    numero,
    casasDecimais,
    separadorMilhares,
    separadorDecimal
  ) => {
    const options = {
      round: casasDecimais,
      decimal: separadorDecimal,
      thousand: separadorMilhares,
    };

    return formatNumber(options)(numero);
  };

  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          <Link
            to={`/dream-pos/turma/turmaedit/${record.id}`}
            style={{ width: "70%" }}
            className="product-img"
          >
            <img
              alt="imagem"
              className="rounded-3"
              src={url_root + "/storage/" + record.imagem}
              style={{ maxWidth: "50px" }}
            />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      title: "Curso",
      dataIndex: "curso",
      sorter: (a, b) => a.curso.designacao.length - b.curso.designacao.length,
      render: (text, record) => (
        <div>
          <div> {record.curso.sigla.toUpperCase()}</div>
          <div>
            [{" "}
            {record.paga_coletivo == 1
              ? " Publico "
              : " Empresa / Personalisado "}{" "}
            ]
          </div>
        </div>
      ),
    },
    {
      title: "Inicio/Fim",
      dataIndex: "dataIni",
      sorter: (a, b) => a.dataIni.length - b.dataIni.length,
      render: (text, record) => (
        <div>
          <div>
            {format(new Date(record.dataIni), "dd/MM/yyyy")} -{" "}
            {format(new Date(record.dataFim), "dd/MM/yyyy")}
          </div>
          <div
            className={
              record.status == 1
                ? "text-danger"
                : record.status == 2
                ? "text-warning"
                : record.status == 3
                ? "text-warning"
                : "text-success"
            }
          >
            [{" "}
            {record.status == 1
              ? "Anulada"
              : record.status == 2
              ? "Em inscrição"
              : record.status == 3
              ? "A Decorrer"
              : "Encerrada"}{" "}
            ]
          </div>
        </div>
      ),
    },
    {
      title: "Horario",
      dataIndex: "hora1",
      sorter: (a, b) => a.hora1.length - b.hora1.length,
      render: (text, record) => (
        <div>
          <div>
            {record.hora1.substring(0, 5) +
              " - " +
              record.hora2.substring(0, 5)}
          </div>
          <div>
            [{" "}
            {record.periodo == 1
              ? "Manhã"
              : record.periodo == 2
              ? "Tarde"
              : "Noite"}{" "}
            ]
          </div>
        </div>
      ),
    },
    {
      title: "Preço ( Kz )",
      dataIndex: "preco",
      sorter: (a, b) => a.preco.length - b.preco.length,
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div
            title={
              "Percentagem de pagamento atual para as " +
              record.sala.acentos +
              " vagas previstas"
            }
          >
            [{" "}
            {formatarNumero(
              Math.round(
                calcularTotalPagamentosFormandos(record.pagamentos) * 100
              ) /
                (record.preco * record.formandos.length),
              1,
              ".",
              ","
            )}
            % ]
          </div>
        </div>
      ),
    },
    {
      title: "Modo",
      dataIndex: "modo",
      sorter: (a, b) => a.sabados.length - b.sabados.length,
      render: (text, record) => (record.modo == 1 ? "Presencial" : "Online"),
    },
    {
      title: "Vagas",
      dataIndex: "sala",
      sorter: (a, b) => a.sabados.length - b.sabados.length,
      render: (text, record) =>
        record?.formandos.length + " / " + record.sala.acentos,
    },
    {
      title: "Dias / Registado por",
      dataIndex: "sabados",
      sorter: (a, b) => a.sabados.length - b.sabados.length,
      render: (text, record) => (
        <div>
          <div>[ {record.sabados == 2 ? "Sábados" : "Segunda á Sexta"} ]</div>
          <div>{record?.atendente?.name.toUpperCase()}</div>
        </div>
      ),
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>
          <div className="icons-container">
            <Link
              title="editar dados cliente"
              className="me-3"
              to={`/dream-pos/turma/turmaedit/${record.id}`}
            >
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div
              title="Eliminar este cliente"
              className="confirm-text"
              onClick={() => confirmEliminar(record.id)}
            >
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>
        </>
      ),
    },
  ];

  const renderOptioncursos = (cursos) => {
    return cursos?.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao}</option>
      </React.Fragment>
    ));
  };

  const handleSelectCursoChange = (valor) => {
    setCursoSelecionado(valor);
  };

  const irParaFormando = (id) => {
    const element = document.querySelector(".btn-close");

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push("/dream-pos/users/newformandoedit/" + id);
  };

  const handleFilterSubmit = async () => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("campanha_id", id);
      formData.append("curso_id", curso_selecionado);
      formData.append("modo", modoFilter);
      formData.append("preco", precoFilter);
      formData.append("periodo", periodoFilter);
      formData.append("frequencia", frequenciaFilter);
      formData.append("paga_coletivo", coletivoFilter);
      formData.append("estado", estadoFilter);

      console.log("curso_id", curso_selecionado);
      console.log("data1", data1Filter);
      console.log("data2", data2Filter);
      console.log("modo", modoFilter);
      console.log("preco", precoFilter);
      console.log("periodo", periodoFilter);
      console.log("frequencia", frequenciaFilter);
      console.log("paga_coletivo", coletivoFilter);
      console.log("estado", estadoFilter);

      const response = await http.post("/campanha/turma/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //console.log(response.data.turmas);
      setturma(response.data.turmas);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleEliminarMultiplos = async () => {
    // Exibe a mensagem de confirmação
    Swal.fire({
      title: "Tem certeza que quer eliminar ?",
      text: "Acção de eliminar turmas é irreversivel, pretende continuar.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Envie uma solicitação para a API para eliminar os usuários selecionados
          const response = await http.post("/turma/eliminar-multiple", {
            selectedturmaIds,
          });

          getturma();

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } catch (error) {
          console.error(error);

          toast.error(
            "Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const getturma = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/campanha/turmas/" + id);

      console.log("turmas :");
      console.log(res.data.turmas);

      setturma(res.data.turmas);

      setLoading(false);

      /* PDF E EXCEL */

      // Verifica se o array de turmas está vazio antes de prosseguir
      if (turmas.length === 0) {
        console.warn("Array de turmas vazio.");
        return;
      }

      const data = turmas?.map((turma) => ({
        name: turma.nome,
        email: turma.email,
        telefone: turma.telefone,
        estado: turma.estado === 1 ? "Ativo" : "Desativado",
      }));

      // Preenche os valores no estado após a conclusão de getturma()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(["Nome", "Email", "Telefone", "Estado"]);
      setFicheiroNome("turmas");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("turma não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleSelectedItemChange = async (newState) => {
    try {
      // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
      const response = await http.post("/turma/activate-multiple", {
        selectedturmaIds,
        newState,
      });

      getturma();

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
      // Limpe o estado dos IDs dos usuários selecionados
    }
  };

  const getcursos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cursos");

      setcurso(res.data.cursos);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("turma não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const state10 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: (cursosInscricoes || []).map((cursoInscricao) =>
          cursoInscricao?.sigla ? cursoInscricao.sigla.toUpperCase() : "N/A"
        ),
      },
    },
    series: [
      {
        name: "Total inscrições ",
        data: (cursosInscricoes || []).map((cursoInscricao) =>
          cursoInscricao?.quantidadeInscricoesNaCampanha !== undefined
            ? cursoInscricao.quantidadeInscricoesNaCampanha
            : 0
        ),
      },
    ],
  };

  // Extrai os totais de associações e os dias do objeto retornado pela API
  const segunda_sexta = Object.values(estatistica?.dias_uteis || {});
  const sabados = Object.values(estatistica?.sabados || {});

  const cursos_campanha = Object.keys(estatistica?.cursos || {}).map((key) =>
    String(key)
  );

  const state13 = {
    series: [
      {
        name: "Dias Uteis",
        data: segunda_sexta,
      },
      {
        name: "Sabados",
        data: sabados,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: cursos_campanha,
      },
      yaxis: {
        title: {
          text: "Qtd",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          },
        },
      },
    },
  };

  // Extrai os totais de formandos por período
  const manha = Object.values(periodos?.manha || {});
  const tarde = Object.values(periodos?.tarde || {});
  const noite = Object.values(periodos?.noite || {});

  const statePeriodos = {
    series: [
      {
        name: "Manhã",
        data: manha,
      },
      {
        name: "Tarde",
        data: tarde,
      },
      {
        name: "Noite",
        data: noite,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: cursos_campanha,
      },
      yaxis: {
        title: {
          text: "Qtd",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + "";
          },
        },
      },
    },
  };

  const getFormandosDaCampanha = async (campanha_id, curso_id) => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const response = await http.get(
        "/campanha/curso/formandos/" + campanha_id + "/" + curso_id
      );

      setTurmaCurso(response.data?.turmas);
      console.log(response.data);

      setLoading(false);

      const element = document.querySelector(
        "#trigger_modal_formandos_na_turma"
      );

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  /* Turmas Fim */

  const navegarParaLista = () => {
    history.push("/dream-pos/campanha/campanhalista");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getturma()

        await getcampanha();
        await getturma();
        await getcursos();
      } catch (error) {
        console.error("Erro ao obter turmas:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const getcampanha = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/campanha/" + id);

      console.log("Carregado... estatistica");
      console.log(res.data?.estatistica);

      setInputs(res.data?.campanha);
      setEstatistica(res.data?.estatistica);
      setPeriodos(res.data?.periodos);
      setCursoTurmas(res.data?.turmas);

      const inscricoes = res?.data?.cursosInscricoes || [];
      setCursoInscricao(inscricoes);

      console.log("Carregado... turmas por curso");
      console.log(res.data?.turmas);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const turmasArray = Object.keys(curso_turmas).map((key) => {
    const turmas = curso_turmas[key];
    const totalFormandos = turmas.reduce((sum, turma) => {
      if (turma.formandos && Array.isArray(turma.formandos)) {
        return sum + turma.formandos.length;
      }
      return sum;
    }, 0);
    return {
      curso: key,
      turmas: turmas,
      totalFormandos: totalFormandos,
    };
  });

   // Calcular o total de turmas e formandos
   const totalTurmas = turmasArray.reduce((sum, curso) => sum + curso.turmas.length, 0);
   const totalFormandos = turmasArray.reduce((sum, curso) => sum + curso.totalFormandos, 0);
 

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.descricao.length < 2) {
          setError(
            "O designação da campanha deve ter pelo menos dois caracteres."
          );
          setLoading(false);

          // Show a error toast
          toast.error(
            "O designação da campanha deve ter pelo menos dois caracteres.",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );

          return;
        }

        await updatecampanha();

        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.log("Erro:");
          console.log(error);

          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updatecampanha = async () => {
    if (permissaoAtualizar) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("descricao", inputs.descricao);
        formData.append("data1", inputs.data1);
        formData.append("data2", inputs.data2);

        const response = await http.post("/campanha/update", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de campanhas após o atualização bem-sucedida
            //history.push('/dream-pos/users/userlists');
            if (!permanecerNaPagina) {
              navegarParaLista();
            }
          },
        });
      } catch (error) {
        console.log("Erro:");
        console.log(error);

        toast.error(
          "Ocorreu um erro ao carregar o campanha. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  return (
    <div className="page-wrapper">
      {/* Edit campanha */}
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i
              alt="Image"
              className="header-image fa fa-university border p-3 rounded-3"
            ></i>
            <div>
              <h4>Gestão de campanhas</h4>
              <h6>Editar campanha</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link
              to="/dream-pos/campanha/novacampanha"
              className="btn btn-added"
            >
              <i className="fa fa-plus"></i>&nbsp; Add Nova campanha
            </Link>
          </div>
        </div>

        {/* /add */}
        <div className="card">
          <div className="card-body">
            <form className="user">
              <div className="row">
                <div className="col-lg-2 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Data inicio</label>
                    <input
                      type="date"
                      name="data1"
                      id="data1"
                      value={inputs.data1}
                      onChange={handleInputsValueChanged}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-lg-2 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Data Fim</label>
                    <input
                      type="date"
                      name="data2"
                      id="data1"
                      value={inputs.data2}
                      onChange={handleInputsValueChanged}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-lg-8 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Descrição campanha</label>
                    <input
                      type="text"
                      name="descricao"
                      id="descricao"
                      value={inputs.descricao}
                      onChange={handleInputsValueChanged}
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <a onClick={submitForm} className="btn btn-submit me-2">
                  {loading ? <Spinner /> : "Salvar"}
                </a>
                <a onClick={navegarParaLista} className="btn btn-cancel">
                  Cancelar
                </a>
              </div>
            </form>
          </div>
        </div>
        {/* /add */}
      </div>
      {/* fim edit campanha */}

      {/* inicio grafico formandos por turma */}

      <div className="content">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">
                  Formandos por curso nesta campanha
                </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive dataview">
                  {state10.series[0].data.length > 0 ? (
                    <Chart
                      options={state10.options}
                      series={state10.series}
                      type="bar"
                    />
                  ) : (
                    <p>Sem dados disponíveis</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Chart */}
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <h5 className="card-title">Sábados X Dias Uteis</h5>
              </div>
              <div className="card-body">
                <div id="s-col" />
                <Chart
                  options={state13.options}
                  series={state13.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
          {/* /Chart */}

          {/* Chart */}
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <h5 className="card-title">Manhã X Tarde X Noite</h5>
              </div>
              <div className="card-body">
                <div id="s-col" />
                <Chart
                  options={statePeriodos.options}
                  series={statePeriodos.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
          {/* /Chart */}

          {/* Chart */}
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <h5 className="card-title">Turmas Por Curso</h5>
              </div>
              <div className="card-body">
                <div id="s-col" />

                <table className="table table-bordered table-responsive datanew dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Imagem</th>
                      <th>Curso</th>
                      <th>Qtd Turmas</th>
                      <th>Qtd Formandos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {turmasArray.map((curso, index) => (
                      <tr
                        key={index}
                        onClick={() =>
                          getFormandosDaCampanha(id, curso.turmas[0]?.curso_id)
                        }
                      >
                        <td>
                          <img
                            className="img-profile rounded-circle"
                            width="40"
                            height="40"
                            src={`${url_root}/storage/${curso.turmas[0].curso.imagem}`}
                            alt="curso foto"
                          />
                        </td>
                        <td>{curso.curso}</td>
                        <td>{curso.turmas.length}</td>
                        <td>{curso.totalFormandos}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="2">
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>{totalTurmas}</strong>
                      </td>
                      <td>
                        <strong>{totalFormandos}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          {/* /Chart */}
        </div>
      </div>

      {/* fim grafico formandos por turma */}

      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i
              alt="Image"
              className="header-image fa fa-book border p-3 rounded-3"
            ></i>
            <div>
              <h4>Turmas desta campanha</h4>
              <h6>Lista de turmas</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/turma/novaturma" className="btn btn-added">
              <img src={PlusIcon} alt="img" className="me-1" />
              Adicionar turma
            </Link>
          </div>
        </div>
        {/* /product list */}

        <div className="card">
          <div className="card-body">
            <Tabletop
              inputfilter={inputfilter}
              togglefilter={togglefilter}
              handleSelectedItemChange={handleSelectedItemChange}
              handleEliminarMultiplos={handleEliminarMultiplos}
              generatePDF={generatePDF}
              generateExcel={generateExcel}
              logoTipo={logoTipo}
              tableHeader={tableHeader}
              tableData={tableData}
              ficheiroNome={ficheiroNome}
              setNomeFilter={setNomeFilter}
              handleFilterSubmit={handleFilterSubmit}
            />

            {/* /Filter */}
            <div
              className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
              id="filter_inputs"
              style={{ display: inputfilter ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-8 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        name="curso_id"
                        onChange={(e) =>
                          handleSelectCursoChange(e.target.value)
                        }
                        value={curso_selecionado}
                        className="form-control select"
                      >
                        <option value={-1}>Selecione o curso</option>
                        {renderOptioncursos(cursos)}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Preço"
                        value={precoFilter}
                        onChange={(e) => setPrecoFilter(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={modoFilter}
                        onChange={(e) => setModoFilter(e.target.value)}
                      >
                        <option value="-1">Todos modos</option>
                        <option value="1">Presencial</option>
                        <option value="2">Online</option>
                        <option value="3">Hibrido</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        onChange={(e) => setEstadoFilter(e.target.value)}
                      >
                        <option value="-1">Todos estados</option>
                        <option value="1">Anulada</option>
                        <option value="2">Em inscrição</option>
                        <option value="3">A Decorrer</option>
                        <option value="4">Encerrada</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={frequenciaFilter}
                        onChange={(e) => setFrequenciaFilter(e.target.value)}
                      >
                        <option value="-1">Todas as frequências</option>
                        <option value="1">Dias utéis</option>
                        <option value="2">Sábados</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={periodoFilter}
                        onChange={(e) => setPeriodoFilter(e.target.value)}
                      >
                        <option value="-1">Todos os periodos</option>
                        <option value="1">Manhã</option>
                        <option value="2">Tarde</option>
                        <option value="3">Noite</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={coletivoFilter}
                        onChange={(e) => setColetivoFilter(e.target.value)}
                      >
                        <option value="-1">Todos os clientes</option>
                        <option value="1">Particulares ( publico )</option>
                        <option value="2">Empresa ou grupo organizado </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img
                          src={search_whites}
                          alt="img"
                          onClick={handleFilterSubmit}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              {loading ? (
                <span>Carregando...</span> // Render the spinner component when loading is true
              ) : (
                /*<Table columns={columns} dataSource={turmas} />*/

                <Datatable
                  columns={columns}
                  dataSource={turmas}
                  onSelectedRowsChange={(selectedturmaIds) =>
                    setSelectedturmaIds(selectedturmaIds)
                  }
                />
              )}
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>

      {/* Modal formandos por curso / turmas */}

      {/* Add Event Modal */}
      <div id="formandos_curso" className="modal modal-md  fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-lg modal-center">
            <div className="modal-header">
              <h5 className="modal-title">Formandos no curso</h5>

              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"></span>
              </button>
            </div>

            <div className="modal-body">
              {/* inicio tabela */}

              <div className="row table-responsive">
                {turmas_curso?.map((turma, index) => (
                  <table
                    className="table table-bordered table-responsive datanew dataTable no-footer"
                    key={index}
                  >
                    <thead>
                      <tr>
                        <th colSpan="9">
                          <span
                            className="icon"
                            title="Ver info desta turma"
                            onClick={() => irParaTurma(turma?.id)}
                          >
                            <i className="fa fa-calendar text-warning"></i>
                          </span>
                          {" - "}[{" "}
                          {turma?.sabados == 2 ? "Sábados" : "Segunda á Sexta"}{" "}
                          ]{" - "}
                          {turma?.curso?.designacao.toUpperCase()} |{" "}
                          {turma?.dataIni} - {turma?.dataFim} |{" "}
                          {turma?.hora1.substring(0, 5)} -{" "}
                          {turma?.hora2.substring(0, 5)}
                          <hr />
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>Nº</th>
                        <th>Foto</th>
                        <th>Nome</th>
                        <th>Tel/Email</th>
                        <th>Atendido</th>
                        <th>Operações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {turma?.formandos?.map((cdata, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              className="img-profile rounded-circle"
                              width="40"
                              height="40"
                              src={
                                url_root +
                                "/storage/" +
                                cdata?.formando.fotografia
                              }
                              alt="fotografia"
                            />
                          </td>
                          <td
                            className={
                              cdata?.situacao == 1 ? "" : "text-danger"
                            }
                          >
                            {cdata?.formando.name}
                          </td>
                          <td
                            className={
                              cdata?.situacao == 1 ? "" : "text-danger"
                            }
                          >
                            {cdata?.formando.telefone}
                            <br></br>
                            {cdata?.formando.email}
                          </td>

                          <td>{cdata?.atendente?.name.toUpperCase()}</td>

                          <td>
                            <span
                              className="icon"
                              onClick={() => irParaFormando(cdata?.formando.id)}
                              title="editar dados do formando"
                            >
                              <i className="fa fa-pencil"></i>
                            </span>
                            &nbsp;&nbsp;
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </div>

              {/* fim tabela */}
            </div>
          </div>
        </div>
      </div>

      <a
        href="#"
        className="btn btn-secondary col-sm-5 d-none"
        data-bs-toggle="modal"
        data-bs-target="#formandos_curso"
        id="trigger_modal_formandos_na_turma"
      ></a>

      {/* /Add Event Modal */}

      <ToastContainer />
    </div>
  );
};

CampanhaEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
};

export default CampanhaEdit;
